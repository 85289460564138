import React from 'react';
import './Bio.css';

const Bio: React.FC = () => (
  <section className="bio">
    <p>
    Hi, I'm a student at the University of Michigan--Ann Arbor studying Computer Science Engineering and Statistics. I love making software and solving hard, real-world problems. Please feel free to reach out to me at my email below!
    </p>
    <div className="contact-info">
      <h3>Contact</h3>
      <p>Email: kmans@umich.edu</p>
    </div>
  </section>
);

export default Bio;