import React from 'react';
import './ProjectItem.css';
import { ReactComponent as LinkIcon } from '../assets/link.svg';

interface ProjectItemProps {
  name: string;
  description: string;
  mediaType: 'image' | 'video';
  media: string;
  link?: string;
}

const ProjectItem: React.FC<ProjectItemProps> = ({ name, description, mediaType, media, link }) => (
  <div className="project">
    <h3>{name}</h3>
    <p>{description}</p>
    {mediaType === 'image' ? (
      <img src={media} alt={name} />
    ) : (
      <iframe
        width="100%"
        height="315"
        src={media}
        title={name}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    )}
    {link && (
      <div className="project-link">
        <a href={link} target="_blank" rel="noopener noreferrer" className="link-icon-button" title="Visit Project">
          <LinkIcon />
        </a>
      </div>
    )}
  </div>
);

export default ProjectItem;