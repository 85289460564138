import React from 'react';
import './SocialLinks.css';
import { ReactComponent as GithubLogo } from '../assets/github-mark.svg';
import { ReactComponent as LinkedinLogo } from '../assets/linkedin.svg';
import { ReactComponent as XLogo } from '../assets/x.svg';

const SocialLinks: React.FC = () => (
  <nav>
    <a href="https://x.com/therealkmans" target="_blank" rel="noopener noreferrer">
      <XLogo className="social-icon" />
    </a>
    <a href="https://github.com/kunal-mansukhani" target="_blank" rel="noopener noreferrer">
      <GithubLogo className="social-icon" />
    </a>
    <a href="https://www.linkedin.com/in/kunal-mansukhani-b03656220/" target="_blank" rel="noopener noreferrer">
      <LinkedinLogo className="social-icon" />
    </a>
  </nav>
);

export default SocialLinks;