import React from 'react';
import SocialLinks from './SocialLinks';
import Bio from './Bio';
import './Header.css';

const Header: React.FC = () => (
  <header>
    <h1>Kunal Mansukhani</h1>
    <SocialLinks />
    <Bio />
  </header>
);

export default Header;